@import "variables";

/* ######## PLUGIN-STYLES ######## */
@import "plugins/accordion";
@import "plugins/bootstrap-datepicker";
@import "plugins/buttons.bootstrap5.min";
@import "plugins/c3-chart";
@import "plugins/clndr";
@import "plugins/dataTables.bootstrap5";
@import "plugins/date-picker";
@import "plugins/daterangepicker";
@import "plugins/demo";
@import "plugins/fancy_fileupload";
@import "plugins/fileupload";
@import "plugins/flipclock";
@import "plugins/fullcalendar";
@import "plugins/gallery";
@import "plugins/intlTelInput";
@import "plugins/ion.rangeSlider";
@import "plugins/ion.rangeSlider.skinFlat";
@import "plugins/jquery-jvectormap-2.0.2";
@import "plugins/jquery.countdown";
@import "plugins/jquery.growl";
@import "plugins/jquery.steps";
@import "plugins/jquery.timepicker";
@import "plugins/jquery.transfer";
@import "plugins/jquerysctipttop";
@import "plugins/jquerytransfer-icon_font";
@import "plugins/leaflet";
@import "plugins/main";
@import "plugins/morris";
@import "plugins/multi.min";
@import "plugins/multiple-select";
@import "plugins/notifIt";
@import "plugins/nv.d3";
@import "plugins/owl.carousel";
@import "plugins/perfect-scrollbar";
@import "plugins/quill.bubble";
@import "plugins/quill.snow";
@import "plugins/responsive.bootstrap5";
@import "plugins/richtext";
@import "plugins/select2.min";
@import "plugins/sidebar";
@import "plugins/smart_wizard_theme_arrows";
@import "plugins/smart_wizard_theme_dots";
@import "plugins/smart_wizard";
@import "plugins/spectrum";
@import "plugins/star-rating-svg";
@import "plugins/sumoselect";
@import "plugins/sweetalert";
@import "plugins/tabs";
@import "plugins/timeline.min";
@import "plugins/treeview";