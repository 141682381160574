/*-----Pricing tables-----*/

.pricing-divider {
	box-shadow: 0px 0px 30px $black-2;
}

.pricing {
	color: $white;
}

.pricing1 {
	color: #707070;
}

.pricing {
	.card-category {
		background: $white-3;
		padding: 10px 0;
		color: $white;
	}
	.list-unstyled li {
		border-bottom: 1px solid $white-1;
		padding: 7px 0;
	}
}

.pricing1 {
	.list-unstyled li {
		border-bottom: 1px solid $border;
		padding: 7px 0;
	}
	.card-category {
		background: $white-3;
		padding: 20px 0;
		border-radius: 7px 7px 0 0;
		color: #Fff;
	}
}

.pricing-table {
	width: 100%;
	margin: 0 auto;
	transition: all .3s ease;
	-o-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	border-radius: 0px;
	&:hover {
		box-shadow: 0px 0px 30px $black-2;
		> {
			.panel-footer-landing {
				box-shadow: 0px 0px 30px $black-05 inset;
				-webkit-transition: all .3s ease;
			}
			.panel> {
				.panel-body-landing {
					background: #1643A3;
					-webkit-transition: all .3s ease;
				}
				.panel-heading-landing-box {
					background: $white !important;
					color: #333 !important;
					-webkit-transition: all .3s ease;
				}
				.controle-header {
					background: #1643A3 !important;
					/*    border: solid 2px #5CB85C !important;*/
					-webkit-transition: all .3s ease;
				}
				.panel-footer {
					background: #1643A3 !important;
					/*    border: solid 2px #5CB85C !important;*/
					-webkit-transition: all .3s ease;
					text-align: center;
				}
			}
		}
	}
}

.princing-item:hover {
	.deco-layer--1 {
		-webkit-transform: translate3d(15px, 0, 0);
		transform: translate3d(15px, 0, 0);
	}
	.deco-layer--2 {
		-webkit-transform: translate3d(-15px, 0, 0);
		/* transform: translate3d(-15px, 0, 0); */
	}
	box-shadow: 0px 0px 30px $black-1;
}

.btn-price:hover {
	background: $white !important;
	color: #1643A3 !important;
	-webkit-transition: all .3s ease;
}

.pricing-table {
	&:hover {
		>.panel>.controle-header>.panel-title-landing {
			color: $white !important;
			-webkit-transition: all .3s ease;
			font-size: 35px;
		}
		>.panel>.panel-body-landing>.table>tbody>tr>td {
			font-weight: 500;
			-webkit-transition: all .3s ease;
		}
	}
	>.panel>.controle-header>.panel-title-landing {
		color: $black !important;
		font-size: 35px;
	}
}

.table> {
	tbody>tr>th {
		font-weight: 500;
		-webkit-transition: all .3s ease;
	}
	tfoot>tr> {
		td,
		th {
			font-weight: 500;
			-webkit-transition: all .3s ease;
		}
	}
	thead>tr> {
		td,
		th {
			font-weight: 500;
			-webkit-transition: all .3s ease;
		}
	}
}

.panel-body-landing:hover>.table>tbody>tr>td {
	color: $white !important;
	-webkit-transition: all .3s ease;
}

.panel-heading-landing {
	background: #f7f7f7 !important;
	padding: 20px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	border: solid 2px #1643A3 !important;
	border-bottom: none !important;
	text-align: center;
	margin-top: 20px;
}

.col-sm-4 .pricing-table .table td {
	padding: 0.75rem;
	vertical-align: top;
	text-align: left;
}


/*------ Pricing Styles ---------*/

.panel.price {
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	>.panel-heading {
		-moz-transition: all .3s ease;
		-o-transition: all .3s ease;
		-webkit-transition: all .3s ease;
	}
	h3 {
		margin-bottom: 0;
		padding: 20px 0;
	}
}

.panel-heading {
	padding: 10px 15px;
	border-bottom: 1px solid $white-2;
	background: $white;
}

.panel.price {
	>.panel-heading {
		color: $white;
		border-radius: 5px 5px 0 0;
	}
	.list-group-item {
		&:last-child {
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}
		&:first-child {
			border-top-right-radius: 0px;
			border-top-left-radius: 0px;
		}
	}
	margin-bottom: 1.5rem;
	box-shadow: 0px 22px 68px -9px rgba(0, 0, 0, 0.15);
	border-radius: 7px;
	background: $white;
	&:hover {
		box-shadow: 0px 0px 30px $black-2;
	}
}

.price {
	.panel-footer {
		border-bottom: 0px;
		background-color: $white;
		border-left: 0;
		border-right: 0;
	}
	&.panel-color>.panel-body {
		background-color: $white;
	}
}

.product-price {
	font-size: 1rem;
	strong {
		font-size: 1.5rem;
	}
}

.pricing-divider-img {
	position: absolute;
	left: 0;
	width: 100%;
}

.price-detail-wrap .rating-stars {
	text-align: left !important;
}