.item-card-img {
	overflow: hidden;
}


/*--item-card2-img--*/

.item-card2-img {
	position: relative;
	overflow: hidden;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}

.item-card2-desc ul li {
	margin-right: 1.5rem;
	font-size: 13px;
	&:last-child {
		margin-right: 0;
	}
}


/*--item-card2--*/

.item-card2-icons {
	position: absolute;
	top: 20px;
	right: 15px;
	z-index: 999;
	a {
		width: 2rem;
		display: inline-block;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2.1rem;
		border-radius: 50px;
		color: $white;
	}
}


/*--item-list--*/

.item-list .list-group-item {
	border: 0;
	margin-bottom: 0;
	border-bottom: 1px solid rgba(167, 180, 201, 0.3);
	i {
		margin-right: 5px;
		width: 25px;
		height: 25px;
		border-radius: 2px;
		font-size: 12px;
		text-align: center;
		line-height: 25px;
		color: $white !important;
	}
}


/*--item-user--*/

.item-user .item-user-icons a {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	margin-top: 0.3rem;
	&:hover {
		color: $white;
	}
}

.item-box {
	.item-box-wrap {
		margin-top: 15px;
	}
	.stamp i {
		font-size: 18px;
		font-size: 25px;
		line-height: 2;
	}
	.item-box-wrap h5 {
		font-size: 20px;
		font-weight: 500;
	}
}