
.app-sidebar .mCSB_draggerContainer {
	right: -11px !important;
}
@media (min-width: 992px) {
	.app.sidenav-toggled {
		.app-content {
			margin-left: 0;
		}
		.app-sidebar {
			left: 230px;
		}
		.app-sidebar__overlay {
			visibility: hidden;
		}
	}
	.app-sidebar {
		padding-top: 55px;
	}
	.side-header {
		width: 270px;
		left: 0;
		right: 0;
		top: 0;
		position: fixed;
		z-index: 99;
	    background: $white;
	}
	.app.sidebar-mini.sidenav-toggled .side-header {
		width: 80px !important;
	}
	.sidebar-mini footer.footer{
		padding: 1.25rem 1.25rem 1.25rem 270px;
	}
	.sidebar-mini.sidenav-toggled footer.footer{
		padding: 1.25rem 1.25rem 1.25rem 80px;
	}
}
@media (max-width: 991px) {
	.app {
		overflow-x: hidden;
		.app-sidebar {
			left: -270px;
		}
		&.sidebar-mini {
			&.sidenav-toggled .side-menu .side-menu__icon {
				margin-right: 8px;
			}
			&.sidenav-toggled .side-menu h3 {
				display: block !important;
			}
		}
		.app-sidebar__overlay {
			visibility: hidden;
		}
		&.sidenav-toggled {
			.app-content {
				margin-left: 0;
			}
			.app-sidebar {
				left: 0;
			}
			.app-sidebar__overlay {
				visibility: visible;
			}
		}
		&.sidebar-mini.sidebar-gone.sidenav-toggled .app-sidebar {
			left: 0;
		}
		.app-header .header-brand1{
			width: inherit;
			position: absolute;
			left:0;
			right: 0;
		}
	}
}
.app-content {
	margin-bottom: 0 !important;
	-webkit-transition: margin-left 0.2s ease;
	-o-transition: margin-left 0.2s ease;
	transition: margin-left 0.2s ease;
	overflow: hidden;
    margin-top: 74px;
	.side-app {
		padding: 0px 30px 0 30px;
	}
}
.footer .container {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
}
@media (min-width: 992px) {
	.app-content {
		margin-left: 270px;
	}
}
@media (max-width: 991px) {
	.app-content {
		min-width: 100%;
	}
}
@media (max-width: 480px) {}
@media print {
	.app-content {
		margin: 0;
		padding: 0;
		background-color: $white;
	}
}
.light-text {
	font-weight: 300 !important;
}
.semibold-text {
	font-weight: 600 !important;
}
.line-head {
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
}
/*----- Componant: Top Navigation Bar ----- */

.app-header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	z-index: 999 !important;
	border-bottom: 1px solid $border;
	background: $white;
	transition: padding-left 0.3s ease;
}
@media (min-width: 992px) {
	.app-header {
		padding-right: 10px;
		padding-left: 285px;
		z-index: 9 !important;
	}
}
@media print {
	.app-header {
		display: none;
	}
}
.app-header__logo {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	color: $white;
	text-align: center;
	font-family: 'Niconne';
	padding: 0 15px;
	font-size: 26px;
	font-weight: 400;
	line-height: 50px;
	&:focus, &:hover {
		text-decoration: none;
	}
}
@media (min-width: 992px) {
	.app-header__logo {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		display: block;
		width: 230px;
	}
}
.app-sidebar__toggle {
	color: #495584;
	z-index: 1;
	// margin-top: 5px;
	transition: padding-left 0.3s ease;
	padding: 9px;
    text-align: center;
    height: 2.5rem;
    font-size: 1.5rem;
    line-height: 1;
    position: relative;
    background: $white;
    border-radius: 50%;
    box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	&:hover {
		color: #1f1e1e;
	}
	&:before {
		content: "\e907";
		transition: all .25s;
		font-size: 21px;
		font-family: 'feather';
	}
	&:focus, &:hover {
		text-decoration: none;
	}
}

@media (max-width: 991px) {
	.app-sidebar__toggle {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
	}
}
.app-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
@media (min-width: 992px) {
	.app-nav {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
	}
}
.app-nav__item {
	display: block;
	padding: 15px;
	line-height: 20px;
	color: #a8a8a8;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&:hover, &:focus {
		background: rgba(0, 0, 0, 0.1);
		color: #f6f6f6;
	}
}
.app-search {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: center;
	align-self: center;
	margin-right: 15px;
	padding: 10px 0;
}
@media (max-width: 480px) {
	.app-search {
		display: none;
	}
}
.app-search__input {
	border: 0;
	padding: 5px 10px;
	padding-right: 30px;
	border-radius: 2px;
	background-color: rgba(255, 255, 255, 0.8);
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&::-webkit-input-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &::placeholder {
		color: rgba(0, 0, 0, 0.4);
	}
}
.app-search__button {
	position: absolute;
	right: 0;
	top: 10px;
	bottom: 10px;
	padding: 0 10px;
	border: 0;
	color: rgba(0, 0, 0, 0.8);
	background: none;
	cursor: pointer;
}
.app-notification {
	min-width: 270px;
}
.app-notification__title {
	padding: 8px 20px;
	text-align: center;
	background-color: rgba(0, 150, 136, 0.4);
	color: #333;
}
.app-notification__footer {
	padding: 8px 20px;
	text-align: center;
	background-color: #eee;
}
.app-notification__content {
	max-height: 220px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
	}
}
.app-notification__item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 8px 20px;
	color: inherit;
	border-bottom: 1px solid #ddd;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&:focus, &:hover {
		color: inherit;
		text-decoration: none;
		background-color: #e0e0e0;
	}
}
.app-notification__message, .app-notification__meta {
	margin-bottom: 0;
}
.app-notification__icon {
	padding-right: 10px;
}
.app-notification__message {
	line-height: 1.2;
}
.app-sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	color: #8a98ac;
	z-index: 99;
	background: $white;
	-webkit-box-shadow: 0 8px 24px rgba(229, 228, 230, .4);
	box-shadow: 0 8px 24px rgba(229, 228, 230, .4);
	-webkit-transition: left 0.2s ease, width 0.2s ease;
	-o-transition: left 0.2s ease, width 0.2s ease;
	transition: left 0.2s ease, width 0.2s ease;
	border-right: 1px solid $border;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
	}
}
@media print {
	.app-sidebar {
		display: none;
	}
}
@media (max-width: 991px) {
	.app-sidebar__overlay {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 9;
	}
}
.app-sidebar__user {
	.dropdown-menu {
		top: 10px !important;
	}
	img {
		box-shadow: 0 0 25px rgba(255, 255, 255, 0.1);
		padding: 2px;
		border: 1px solid rgba(255, 255, 255, 0.2);
		box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
	}
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #a8a8a8;
	width: 100%;
	padding: 15px 0 15px;
	display: inline-block;
}
.app-sidebar__user-avatar {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	margin-right: 15px;
}
.app-sidebar__user-name {
	&.text-sm {
		font-size: 12px;
		font-weight: 400;
	}
	font-size: 17px;
	line-height: 1.3;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-bottom: 0;
	overflow: hidden;
	font-weight: 600;
	color: #e5e9ec;
	font-size: 15px;
	margin-top: 5px !important;
}
.app-sidebar__user-designation {
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-bottom: 0;
	overflow: hidden;
	font-weight: 600;
	color: #e5e9ec;
	font-size: 15px;
	margin-top: 5px !important;
}
.side-menu {
	margin-bottom: 0;
	padding: 10px 15px 0 0px;
}
.side-menu__item {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 10px 30px;
	font-size: 14px;
	font-weight: 400;
	-webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
	-o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
	transition: border-left-color 0.3s ease, background-color 0.3s ease;
	color: #495584;
	border-radius: 0 60px 60px 0;
	margin: 2px 0px;
	&.active{
		text-decoration: none;
		color: $white;
		background: $primary-gradient-1;
		border-radius: 0 60px 60px 0;
		box-shadow: 0 7px 12px 0 $primary-02;
		&:hover, &:focus{
			text-decoration: none;
			color: $white;
			background: $primary-gradient-1 !important;
			border-radius: 0 60px 60px 0;
			box-shadow: 0 7px 12px 0 $primary-02;
		}
	}
	&:hover, &:focus{
		text-decoration: none;
		color: $primary-1 ;
		background: #f9fbfd !important;
		border-radius: 0 60px 60px 0;
		.side-menu__label{
			color: $primary-1 !important;
		}
	}
}
@media (min-width: 992px) {
	.app.sidebar-mini.sidenav-toggled .side-menu__label {
		display: none !important;
	}
}
.slide-item {
	&.active, &:hover, &:focus {
		text-decoration: none;
		color: #b5c1d2;
	}
}
.slide-menu a.active {
	color: $primary-1;
}
.slide-item {
	&.active, &:hover, &:focus {
		text-decoration: none;
		color: $primary-1 !important;
	}
}
.slide-menu li {
	.slide-item {
		&:before {
			content: '\e0b1';
			font-family: 'typicons';
			font-style: normal;
			font-weight: normal;
			text-decoration: inherit;
			top: 1px;
			font-size: 11px;
			margin-right: 8px;
		}
	}
	position: relative;
}
.slide.is-expanded a {
	text-decoration: none;
}
.side-menu .side-menu__icon {
	font-size: 18px;
	text-align: center;
	vertical-align: middle;
	margin-right: 5px;
	position: relative;
    top: -1px;
}
.side-menu__label {
	white-space: nowrap;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	position: relative;
	top:1px;
	line-height: inherit;
}
.slide.is-expanded {
	.slide-menu {
		display:block;
	}
	.angle {
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}
.slide-menu {
	display:none;
	padding: 5px 30px;
	font-size: .8rem !important;
	padding-left: 20px;
	margin-left: 26px;
}
.slide-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 8px 8px;
	font-size: 13px;
	color: #76839a;
	.icon {
		margin-right: 5px;
	}
}
.angle {
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: -webkit-transform 0.3s ease;
	-o-transition: transform 0.3s ease;
	transition: transform 0.3s ease;
	transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 2px;
}
@media (min-width: 992px) {
	.sidebar-mini.sidenav-toggled {
		.app-sidebar__user-name, .app-sidebar__user-designation, .angle, .app-sidebar__user-name, .user-notification, .app-sidebar__user-name, .user-info {
			display: none;
		}
		.side-menu__item .side-menu__icon{
			margin-right: 0;
		}
		.app-header.header{
			padding-left: 100px;
		}
		.side-menu {
			padding: 0 0px 40px 00px;
		}
		.side-menu__item.active .side-menu__icon{
			color: #ffffff !important;
		}
		.side-menu .side-menu__icon {
			background:none!important; 
			box-shadow: none; 
		}
		.side-menu__item.active .side-menu__icon {
			color: $white;
			fill: $white;
		}
		.side-badge, .slide-menu open.slide-menu hide .side-badge {
			top: 2px;
			right: 20px;
		}
		.sidebar-mini.sidenav-toggled.user-notification::before {
			background: transparent;
			display: none;
		}
		.app-sidebar__user-name{
			width: 3rem;
			height: 3rem;
			line-height: 3rem;
			font-size: 1rem;
			margin-bottom: 0px !important;
		}
		.app-sidebar__user, .side-menu {
			margin-top: 30px;
		}
		.app-sidebar__user-avatar {
			width: 25px;
			height: 25px;
		}
		.side-menu li .side-menu__item.active:before {
			display: none;
		}
		.app-sidebar__user {
			padding: 12px 0px 12px 0;
			margin-bottom: 0px;
			border-bottom: 1px solid rgba(225, 225, 225, 0.05);
		}
		.profile-img {
			top: 0px;
			right: 19px;
		}
		.app-content {
			margin-left: 80px;
		}
		.app-sidebar {
			left: 0;
			width: 80px;
			overflow: hidden;
			&:hover {
				overflow: visible;
			}
		}
		.side-menu__item {
			overflow: hidden;
		}
		.slide-menu {
			position: absolute;
			opacity: 0;
			border-bottom-right-radius: 4px;
			z-index: 9;
			visibility: hidden;
			-webkit-transition: visibility .3s ease;
			-o-transition: visibility .3s ease;
			transition: visibility .3s ease;
		}
	}
	.app.sidebar-mini.sidenav-toggled {
		.side-menu__item {
			border-radius: 0;
		}
	}
	.sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
		margin: 0 auto;
	}
	.app.sidebar-mini.sidenav-toggled .nav-badge {
		position: absolute;
		top: 8px;
		right: 28px;
		padding: 0.2rem 0.4rem;
		font-size: 11px;
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled-open{
		.app-sidebar{
			width:270px;
		}
		.side-header {
			width: 270px !important;
			display: flex !important;
		}
		.light-logo, .light-logo1, .mobile-light {
			display: none ! important;
		}
		.side-header .header-brand-img.light-logo1 {
			display: block !important;
		}
		.side-menu__item {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			padding: 10px 30px !important;
			border-radius: 0 60px 60px 0;
		}
		.side-menu h3 {
			display: block !important;
		}
		.side-menu__icon{
			font-size: 18px;
			text-align: center;
			vertical-align: middle;
			margin-right: 8px !important;
			position: relative;
    		top: -1px;
		}
		.side-menu__label{
			display: block !important;
			white-space: nowrap;
			-webkit-box-flex: 1;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			position: relative;
			top: 1px;
			line-height: inherit;
		}
		.slide-menu{
			display: none;
			position: unset;
			top: inherit;
			min-width: 0;
			// width: 100%;
			opacity: inherit;
			visibility: inherit;
		}
		.slide.is-expanded .slide-menu {
			display: block !important;
		}
		.side-menu {
			margin-top: 0px !important;
			padding: 10px 15px 0 0px !important
		}	
		.angle{
			display:block;
		}
		.side-badge{
			top: 10px;
		}
	
	}
}
.app.sidebar-mini {
	.side-menu_label {
		display: none;
	}
}
.dropdown-menu {
	border-radius: 0;
	&.dropdown-menu-right {
		left: auto;
	}
}
.dropdown-item {
	.fa, .icon {
		vertical-align: middle;
	}
}
.app-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: #FFF;
	margin: -30px -30px 30px;
	padding: 20px 30px;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	h1 {
		margin: 0;
		font-size: 24px;
		font-weight: 400;
	}
	p {
		margin-bottom: 0;
		font-style: italic;
	}
}
@media print {
	.app-title {
		display: none;
	}
}
@media (max-width: 480px) {
	.app-title {
		margin: -15px -15px 15px;
		padding: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
}
@media (max-width: 480px) {
	.app-title p {
		display: none;
	}
}
.app-breadcrumb {
	margin-bottom: 0;
	text-align: right;
	font-weight: 500;
	font-size: 13px;
	text-transform: capitalize;
	padding: 0;
	text-align: left;
	padding: 0;
	background-color: transparent;
}
@media (max-width: 480px) {
	.app-breadcrumb {
		margin-top: 10px;
	}
}
.tile {
	position: relative;
	background: $white;
	border-radius: 3px;
	padding: 20px;
	-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
	margin-bottom: 30px;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
@media print {
	.tile {
		border: 1px solid #ddd;
	}
}
.sidenav-toggled .app-sidebar__user .avatar-md {
	line-height: 2rem;
	font-size: 1rem;
}
.app-sidebar {
	.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background: rgba(0, 0, 0, 0.05);
	}
	.mCSB_scrollTools {
		.mCSB_dragger .mCSB_dragger_bar, .mCSB_draggerRail {
			background: rgba(255, 255, 255, 0.2);
		}
	}
}
.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
	.nav-badge {
		position: absolute;
		top: 5px;
		left: 57px;
		display: block !important;
		padding: 3px 5px !important;
	}
	.nav-badge1 {
		display: none;
	}
}
.nav-badge {
	border-radius: 30px;
	padding: 0.4em 0.6em;
	font-size: 12px;
}
.user-info {
	.text-dark {
		color: #25252a !important;
		font-weight: 400;
		font-size: 16px;
	}
	.text-muted {
		color: #76839a !important;
	}
}
.side-header {
	.header-brand1 {
		text-align: center;
		margin: 0 auto !important;
	}
	.header-brand-img.desktop-logo {
		display: none;
	}
}
.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo {
	display: none;
}
.side-header .header-brand-img {
	&.toggle-logo {
		display: none;
	}
	&.light-logo1 {
		display: block !important;
	}
}
.sidebar-mini.sidenav-toggled {
	.app-sidebar .side-header .header-brand-img {
		&.light-logo1 {
			display: none !important;
		}
		&.toggle-logo {
			display: none;
			margin-right: 0;
		}
	}
	.sidebar-navs {
		display: none;
	}
}
.side-header {
	display: flex;
	border-bottom: 1px solid $border;
	border-right: 1px solid $border;
	padding: 19px 17px;
	-webkit-transition: left 0.2s ease, width 0.2s ease;
	-o-transition: left 0.2s ease, width 0.2s ease;
	transition: left 0.2s ease, width 0.2s ease;
	height: 75px;
}
.sidebar-mini.sidenav-toggled {
	.app-sidebar__toggle {
		display: block;
		padding-left: 10px;
	}
	.mobile-header .app-sidebar__toggle {
		margin: 0;
		margin-top: 5px;
	}
}
.user-pic {
	margin-bottom: 0.8rem;
}
.sidebar-navs a {
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid $border !important;
	color: #68798b !important;
	border-radius: 5px;
	padding: 0.8rem !important;
}
.app-sidebar .sidebar-navs {
	padding: 0 10px 10px 10px;
	border-bottom: 1px solid $border;
}
.sidebar-navs .nav li:last-child a {
	margin-right: 0 !important;
}
.side-menu h3 {
	color: #76839a;
	text-transform: uppercase;
	letter-spacing: .5px;
	font-size: 11px;
	margin-bottom: 0.5rem;
	margin-top: 1.5rem !important;
	font-weight: 500;
	padding: 5px 30px 5px;
	opacity: .7;
}
.side-menu li:not(:first-child) h3{
	margin-top:0rem;
}
.sidebar-mini.sidenav-toggled .side-menu h3 {
	display: none !important;
}
.sidebar-mini .app-header .light-logo1 {
    display: block !important;
}
@media (min-width: 992px){
	.sidenav-toggled .header-brand-img.light-logo {
		display: block;
		margin-right: 0;
	}
	.app.sidebar-mini.sidenav-toggled .side-menu__item{
		padding: 13px 30px;
	}
}
.side-badge, .slide-menu open.slide-menu hide .side-badge {
    position: absolute;
    top: 10px;
    right: 24px;
    border-radius: 50px;
}
.side-menu__item.active .side-menu__label{
	color: #ffffff !important;
}

/*-- Subslide ---*/

.sub-side-menu__item {
    padding: 8px 8px;
	display: flex;
	color: #76839a;
	.sub-side-menu__label{
		-webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
	}
}
.slide-menu a:before {
    content: '\e0b1';
    margin-right: 10px;
    font-size: 11px;
    position: relative;
    font-family: typicons!important;
    opacity: .6;
	top: 2px;
}
.sub-slide.is-expanded .sub-angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sub-angle {
    float: right;
    line-height: 40px;
    margin-top: 3px;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    -o-transition: transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease,-webkit-transform .3s ease;
}
.sub-slide.is-expanded .sub-slide-menu {
    display: block;
}
.sub-slide-menu {
    display: none;
    padding: 0 15px 15px 0;
    font-size: 13px!important;
    list-style: none;
}
.sub-slide-item {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    list-style: none;
    padding: 8px 0 8px 24px!important;
	color: #76839a;
}

/*-- Subslide2 ---*/

.sub-side-menu__item2 {
    padding: 8px 2px 8px 24px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	color: #76839a;
    font-size: 13.5px;
}
.sub-slide2 .sub-side-menu__label2 {
    flex: 1 1 auto;
}
.sub-angle2 {
    float: right;
    line-height: 40px;
    margin-top: -2px;
    margin-right: -9px;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    -o-transition: transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease,-webkit-transform .3s ease;
}
.sub-slide2.is-expanded .sub-angle2 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sub-slide2.is-expanded .sub-slide-menu2 {
    display: block;
}
.sub-slide-menu2 {
    display: none;
    padding: 0;
    font-size: .8rem!important;
    padding-left: 0;
    list-style: none;
}
.sub-slide-item2 {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    list-style: none;
    padding: 8px 0 8px 40px !important;
	color: #76839a;
}
@media (max-width: 991px){
	.header-brand1 .desktop-logo {
		margin: 0 auto;
		margin-top: 6px;
	}
}
.sidebar-mini.dark-mode.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: block !important ;
}
.sidebar-mini.dark-mode.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.app.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1{
	display: none !important;
}

.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1{
	display: block !important;
}
.app.sidebar-mini.dark-mode.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1{
	display: none !important;
}
.side-menu__item.active .side-menu__icon {
    color: $white;
}
.side-menu__item.active:hover .side-menu__icon {
    color: $white !important;
}
.side-menu__item.active .side-badge{
	background: rgba(0,0,0,0.2) !important;
}

.sidebar-mini .app-sidebar {
    width: 270px;
}

// ICONTEXT-MENU //@at-root

.icontext-menu{
	@media (min-width: 992px) {	
		&.app.sidebar-mini.sidenav-toggled .side-header {	
			width: 120px !important;	
		}	
		&.sidebar-mini.sidenav-toggled footer.footer{	
			padding: 1.25rem 1.25rem 1.25rem 120px;	
		}	
		&.sidebar-mini.sidenav-toggled {
			.app-header.header{
				padding-left: 130px;
			}
			.side-badge, .slide-menu open.slide-menu hide .side-badge {
				right: 42px;
			}
			.app-content {
				margin-left: 120px;
			}
			.app-sidebar {
				width: 120px;
			}
			.side-badge {
				right: 28px;
			}
			&.sidenav-toggled.sidenav-toggled-open .side-menu__label {
				font-size: inherit !important;
			}
		}
	}

	@media (min-width: 992px) {	
		&.app.sidebar-mini.sidenav-toggled .side-menu__item {
			padding: 12px 5px;
			text-align: center;
			display: block;
		}
		&.app.sidebar-mini.sidenav-toggled .side-menu__label {
			display: block !important;
			font-size: 12px;
			padding-top: 0px;
			margin-top: 0;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item {
			display: flex;
			padding: 10px 30px;
			text-align: inherit;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
			width: 270px;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header {
			width: 270px !important;
		}
	}
}

// ----- CLOSED MENU -------//
.closed-leftmenu {
	@media (min-width:992px) {
	  &.sidebar-mini.sidenav-toggled .app-sidebar {
		display: none !important;
	  }
  
	  &.sidebar-mini.sidenav-toggled {
		.app-content {
		  margin-left: 0px !important;
		}
  
		.app-header {
		  padding-left: 18px !important;
		}
	  }
	}
}
  
// ----- ! CLOSED MENU -------//

// ----- HOVER SUBMENU -------//

.hover-submenu {
	@media (min-width: 992px) {	
		&.app.sidebar-mini.sidenav-toggled .side-header {	
			width: 120px !important;	
		}	
		&.sidebar-mini.sidenav-toggled footer.footer{	
			padding: 1.25rem 1.25rem 1.25rem 120px;	
		}	
		.sub-angle2 {
			margin-right: 4px;
		}
		.sub-angle {
			margin-right: 15px;
		}
		&.sidebar-mini.sidenav-toggled {
			.app-header.header{
				padding-left: 130px;
			}
			.side-badge, .slide-menu open.slide-menu hide .side-badge {
				right: 42px;
			}
			.app-content {
				margin-left: 120px;
				min-height: 1300px;
			}
			.app-sidebar {
				width: 120px;
				position: absolute !important;
				.slide{
					position: relative;
				}
				&:hover{
					overflow: visible !important;
				}
			}
			.side-badge {
				right: 32px;
			}
			.slide-menu{
				background-color: $white;
				padding: 10px 0px;
				padding-left: 15px;
				border-radius: 0 5px 5px 0;
				max-height: inherit !important;
				padding-bottom: 8px !important;
				margin-top: 0 !important;
				margin-bottom: 0 !important;
				padding-top: 10px !important;
				height: auto !important;
				overflow: hidden !important;
			}
			&.sidenav-toggled-open{
				.side-menu__label {
					font-size: 12 !important;
				}
				.side-menu h3 {
					display: none !important;
				}
				.angle {
					display: none;
				}
				.side-menu {
					padding: 0 0px 40px 0px !important;
					margin-top: 30px !important;
				}
				.side-menu__item{
					border-radius: 0;
				}
				.side-menu__icon{
					margin-right: 0px !important;
				}
				.side-badge{
					top: 2px;
				}
				.slide:hover .slide-menu {
					visibility: visible;
					display: block !important;
					height: auto !important;
				}
				.slide .slide-menu {
					position: absolute;
					left: 94px;
					min-width: 180px;
					opacity: 1;
					z-index: 9999;
					visibility: hidden;
					top: 0;
					height: auto !important;
				}
			} 
		}
		.ps--active-x>.ps__rail-x,
		.ps--active-y>.ps__rail-y {
			display: none;
		}
	}

	&.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open{
		.app-sidebar .side-header .header-brand-img{
			&.light-logo {
				display: block !important;
			}
			&.light-logo1 {
				display: none !important;
			}
		}
	}

	@media (min-width: 992px) {	
		&.app.sidebar-mini.sidenav-toggled .side-menu__item {
			padding: 12px 5px;
			text-align: center;
			display: block;
		}
		&.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item {
			padding: 12px 5px !important;
		}
		&.app.sidebar-mini.sidenav-toggled .side-menu__label {
			display: block !important;
			font-size: 12px;
			padding-top: 0px;
			margin-top: 0;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
			width: 120px;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header {
			width: 120px !important;
		}
	}
}
  
// -----! HOVER SUBMENU -------//

// ----- HOVER SUBMENU STYLE1 -------//

.hover-submenu1 {
	@media (min-width: 992px) {	
		&.app.sidebar-mini.sidenav-toggled .side-header {	
			width: 80px !important;	
		}	
		&.sidebar-mini.sidenav-toggled footer.footer{	
			padding: 1.25rem 1.25rem 1.25rem 120px;	
		}	
		.sub-angle2 {
			margin-right: 4px;
		}
		.sub-angle {
			margin-right: 15px;
		}
		&.sidebar-mini.sidenav-toggled {
			.app-header.header{
				padding-left: 100px;
			}
			.side-badge, .slide-menu open.slide-menu hide .side-badge {
				right: 42px;
			}
			.app-content {
				margin-left: 80px;
				min-height: 1300px;
			}
			.app-sidebar {
				width: 80px;
				position: absolute !important;
				.slide{
					position: relative;
				}
				&:hover{
					overflow: visible !important;
				}
			}
			.side-badge {
				right: 20px;
			}
			.slide-menu{
				background-color: $white;
				padding: 5px 0px 5px 0;
				border-radius: 0 5px 5px 0;
				max-height: inherit !important;
				padding-bottom: 8px !important;
				margin-top: 0 !important;
				margin-bottom: 0 !important;
				padding-top: 0px !important;
				height: auto !important;
				overflow: hidden !important;
				box-shadow: 8px 8px 17px $black-1;
			}
			.slide-item, .sub-side-menu__item{
				padding-left: 17px;
			}
			&.sidenav-toggled-open{
				.side-menu__label {
					font-size: 12 !important;
				}
				.side-menu h3 {
					display: none !important;
				}
				.angle {
					display: none;
				}
				.side-menu {
					padding: 0 0px 40px 0px !important;
					margin-top: 30px !important;
				}
				.side-menu__item{
					border-radius: 0;
				}
				.side-menu__icon{
					margin-right: 0px !important;
				}
				.side-badge{
					top: 2px;
				}
				.slide:hover .slide-menu {
					visibility: visible;
					display: block !important;
					height: auto !important;
				}
				.slide .slide-menu {
					position: absolute;
					left: 54px;
					min-width: 180px;
					opacity: 1;
					z-index: 9999;
					visibility: hidden;
					top: 0;
					height: auto !important;
				}
				.side-menu-label1 {
					display: block;
					font-size: 15px;
					font-weight: 500;
					border-bottom: 1px solid #e9edf4;
					padding: 10px 15px !important;
					color: $primary-1;
					margin-bottom: 6px;
					a{
						color: $primary-1;
						&::before{
							content: '';
						}
					}
				}
			} 
		}
		.ps--active-x>.ps__rail-x,
		.ps--active-y>.ps__rail-y {
			display: none;
		}
	}

	&.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open{
		.app-sidebar .side-header .header-brand-img{
			&.light-logo {
				display: block !important;
			}
			&.light-logo1 {
				display: none !important;
			}
		}
	}

	@media (min-width: 992px) {	
		&.app.sidebar-mini.sidenav-toggled .side-menu__item {
			padding: 12px 5px;
			text-align: center;
			display: block;
		}
		&.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item {
			padding: 12px 5px !important;
		}
		&.app.sidebar-mini.sidenav-toggled .side-menu__label {
			display: none !important;
			font-size: 12px;
			padding-top: 0px;
			margin-top: 0;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
			width: 80px;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header {
			width: 80px !important;
		}
	}
}
  
// -----! HOVER SUBMENU STYLE1-------//

@media(max-width: 991px){
	.main-sidemenu.container{
		padding-left: 0px;
		padding-right: 0px;
	}
	.side-menu{
		padding: 60px 15px 0 0px !important
	}
	.scrollable-layout .responsive-navbar.navbar .navbar-collapse {
		position: absolute;
	}
	.scrollable-layout .responsive-navbar {
		position: inherit;
	}
}
.horizontalMenucontainer .responsive-navbar.navbar .navbar-collapse {
    top: 73px;
    margin-top: 0;
}

body:not(.horizontal) .side-menu{
	margin-inline: 0 !important;
}

.side-menu-label1 {
	display: none;
}

.horizontal.scrollable-layout .app-sidebar {
	top: 1px;
}
@media (max-width: 991px){
	.horizontal .side-menu {
		margin-inline: 0 !important;
	}
}
