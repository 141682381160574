.daterangepicker {
	position: absolute;
	color: inherit;
	background-color: $white;
	border-radius: 4px;
	width: 278px;
	padding: 4px;
	margin-top: 1px;
	top: 100px;
	left: 20px;
  
	/* Calendars */
  
	&:before, &:after {
	  position: absolute;
	  display: inline-block;
	  border-bottom-color: rgba(0, 0, 0, 0.2);
	  content: '';
	}
  
	&:before {
	  top: -7px;
	  border-right: 7px solid transparent;
	  border-left: 7px solid transparent;
	  border-bottom: 7px solid #ccc;
	}
  
	&:after {
	  top: -6px;
	  border-right: 6px solid transparent;
	  border-bottom: 6px solid $white;
	  border-left: 6px solid transparent;
	}
  
	&.opensleft {
	  &:before {
		right: 9px;
	  }
  
	  &:after {
		right: 10px;
	  }
	}
  
	&.openscenter {
	  &:before, &:after {
		left: 0;
		right: 0;
		width: 0;
		margin-left: auto;
		margin-right: auto;
	  }
	}
  
	&.opensright {
	  &:before {
		left: 9px;
	  }
  
	  &:after {
		left: 10px;
	  }
	}
  
	&.dropup {
	  margin-top: -5px;
  
	  &:before {
		top: initial;
		bottom: -7px;
		border-bottom: initial;
		border-top: 7px solid #ccc;
	  }
  
	  &:after {
		top: initial;
		bottom: -6px;
		border-bottom: initial;
		border-top: 6px solid $white;
	  }
	}
  
	&.dropdown-menu {
	  max-width: none;
	  z-index: 3001;
	}
  
	&.single {
	  .ranges, .calendar {
		float: none;
	  }
	}
  
	&.show-calendar .calendar {
	  display: block;
	}
  
	.calendar {
	  display: none;
	  max-width: 270px;
	  margin: 4px;
  
	  &.single .calendar-table {
		border: none;
	  }
  
	  th {
		white-space: nowrap;
		text-align: center;
		min-width: 32px;
		color: #a3a7b7 !important;
		font-size: 14px;
		font-weight: 400;
	  }
  
	  td {
		white-space: nowrap;
		text-align: center;
		min-width: 32px;
		color: #14171a !important;
		font-size: 14px;
	  }
	}
  
	.calendar-table {
	  border: 1px solid $white;
	  padding: 4px;
	  border-radius: 4px;
	  background-color: $white;
	}
  
	table {
	  width: 100%;
	  margin: 0;
	}
  
	td, th {
	  text-align: center;
	  width: 20px;
	  height: 20px;
	  border-radius: 4px;
	  border: 1px solid transparent;
	  white-space: nowrap;
	  cursor: pointer;
	}
  
	td.available:hover, th.available:hover {
	  background-color: #eceef9;
	  border-color: transparent;
	  color: inherit;
	}
  
	td.week, th.week {
	  font-size: 80%;
	  color: #ccc;
	}
  
	td {
	  &.off {
		background-color: $white;
		border-color: transparent;
		color: #d9dae8;
  
		&.in-range, &.start-date, &.end-date {
		  background-color: $white;
		  border-color: transparent;
		  color: #d9dae8;
		}
	  }
  
	  &.in-range {
		background-color: #eceef9;
		border-color: transparent;
		color: $black;
		border-radius: 0;
	  }
  
	  &.start-date, &.end-date, &.start-date.end-date {
		border-radius: 4px;
	  }
  
	  &.active {
		background-color: #eceef9;
		border-color: transparent;
		color: #000 !important;
  
		&:hover {
		  background-color: #eceef9;
		  border-color: transparent;
		  color: #000 !important;
		}
	  }
	}
  
	th.month {
	  width: auto;
	}
  
	td.disabled, option.disabled {
	  color: #f4f0f2;
	  cursor: not-allowed;
	  text-decoration: line-through;
	}
  
	select {
	  &.monthselect, &.yearselect {
		font-size: 12px;
		padding: 1px;
		height: auto;
		margin: 0;
		cursor: default;
	  }
  
	  &.monthselect {
		margin-right: 2%;
		width: 56%;
	  }
  
	  &.yearselect {
		width: 40%;
	  }
  
	  &.hourselect, &.minuteselect, &.secondselect, &.ampmselect {
		width: 50px;
		color: #a3a7b7;
		margin-bottom: 0;
	  }
	}
  
	.input-mini {
	  border: 1px solid #dfe6e6;
	  border-radius: 4px;
	  height: 30px;
	  line-height: 30px;
	  display: block;
	  vertical-align: middle;
	  margin: 0 0 5px 0;
	  padding: 0 6px 0 28px;
	  width: 100%;
  
	  &.active {
		border: 1px solid #8645ff;
		border-radius: 4px;
	  }
	}
  
	.daterangepicker_input {
	  position: relative;
  
	  i {
		position: absolute;
		left: 8px;
		top: 8px;
		color: #a3a7b7;
	  }
	}
  
	&.rtl {
	  .input-mini {
		padding-right: 28px;
		padding-left: 6px;
	  }
  
	  .daterangepicker_input i {
		left: auto;
		right: 8px;
	  }
	}
  
	.calendar-time {
	  text-align: center;
	  margin: 5px auto;
	  line-height: 30px;
	  position: relative;
	  padding-left: 28px;
  
	  select.disabled {
		color: #ccc;
		cursor: not-allowed;
	  }
	}
  }
  
  .ranges {
	font-size: 11px;
	float: none;
	margin: 4px;
	text-align: left;
  
	ul {
	  list-style: none;
	  margin: 0 auto;
	  padding: 0;
	  width: 100%;
	}
  
	li {
	  font-size: 13px;
	  background-color: #f8f9fa;
	  border: 1px solid #f8f9fa;
	  border-radius: 4px;
	  color: #8645ff;
	  padding: 3px 12px;
	  margin-bottom: 8px;
	  cursor: pointer;
  
	  &:hover, &.active {
		background-color: #8645ff;
		border: 1px solid #8645ff;
		color: $white;
	  }
	}
  }
  
  /*  Larger Screen Styling */
  @media (min-width: 564px) {
	.daterangepicker {
	  width: auto;
  
	  .ranges ul {
		width: 160px;
	  }
  
	  &.single {
		.ranges ul {
		  width: 100%;
		}
  
		.calendar.left {
		  clear: none;
		}
  
		&.ltr {
		  .ranges, .calendar {
			float: left;
		  }
		}
  
		&.rtl {
		  .ranges, .calendar {
			float: right;
		  }
		}
	  }
  
	  &.ltr {
		direction: ltr;
		text-align: left;
  
		.calendar {
		  &.left {
			clear: left;
			margin-right: 0;
  
			.calendar-table {
			  border-right: none;
			  border-top-right-radius: 0;
			  border-bottom-right-radius: 0;
			}
		  }
  
		  &.right {
			margin-left: 0;
  
			.calendar-table {
			  border-left: none;
			  border-top-left-radius: 0;
			  border-bottom-left-radius: 0;
			}
		  }
		}
  
		.left .daterangepicker_input, .calendar.left .calendar-table {
		  padding-right: 12px;
		}
  
		.ranges, .calendar {
		  float: left;
		}
	  }
  
	  &.rtl {
		direction: rtl;
		text-align: right;
  
		.calendar {
		  &.left {
			clear: right;
			margin-left: 0;
  
			.calendar-table {
			  border-left: none;
			  border-top-left-radius: 0;
			  border-bottom-left-radius: 0;
			}
		  }
  
		  &.right {
			margin-right: 0;
  
			.calendar-table {
			  border-right: none;
			  border-top-right-radius: 0;
			  border-bottom-right-radius: 0;
			}
		  }
		}
  
		.left .daterangepicker_input, .calendar.left .calendar-table {
		  padding-left: 12px;
		}
  
		.ranges, .calendar {
		  text-align: right;
		  float: right;
		}
	  }
	}
  }
  
  @media (min-width: 730px) {
	.daterangepicker {
	  .ranges {
		width: auto;
	  }
  
	  &.ltr .ranges {
		float: left;
	  }
  
	  &.rtl .ranges {
		float: right;
	  }
  
	  .calendar.left {
		clear: none !important;
	  }
	}
  }
  
  select {
	border-radius: 0px;
	border-color: rgb(169, 169, 169, 0.3);
  }