/*---------------------------------------------*/

input {
	outline: none;
	border: none;
  }
  
  textarea {
	outline: none;
	border: none;
  
	&:focus {
	  border-color: none !important;
	}
  }
  
  input:focus {
	border-color: none !important;
  
	&::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
	  color: transparent;
	}
  }
  
  textarea:focus {
	&::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
	  color: transparent;
	}
  }
  
  input {
	&::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
	  color: rgba(0, 0, 0, 0.5);
	}
  }
  
  textarea {
	&::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
	  color: rgba(0, 0, 0, 0.5);
	}
  }
  
  /*---------------------------------------------*/
  
  button {
	outline: none !important;
	border: none;
	background: transparent;
  
	&:hover {
	  cursor: pointer;
	}
  }
  
  iframe {
	border: none !important;
  }
  
  /*//////////////////////////////////////////////////////////////////
  [ Utility ]*/
  
  .txt1, .txt2 {
	font-size: 13px;
	line-height: 1.5;
	color: #24214c;
  }
  
  /*//////////////////////////////////////////////////////////////////
  [ login ]*/
  
  .limiter {
	width: 100%;
	margin: 0 auto;
  }
  
  .container-login100 {
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 15px;
	z-index: 999;
  }
  
  .wrap-login100 {
	/*width: 960px;*/
	background: $white;
	overflow: hidden;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 137px 130px 137px 95px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.06);
	border-radius: 5px;
  }
  
  /*------------------------------------------------------------------
  [  ]*/
  
  .login100-pic {
	width: 316px;
  
	img {
	  max-width: 100%;
	}
  }
  
  /*------------------------------------------------------------------
  [  ]*/
  
  .login100-form {
	width: 320px;
  }
  
  .login100-form-title {
	font-size: 24px;
	line-height: 1.2;
	text-align: center;
	width: 100%;
	display: block;
	padding-bottom: 30px;
	color: #424344;
  }
  
  /*---------------------------------------------*/
  
  .wrap-input100 {
	position: relative;
	width: 100%;
	z-index: 1;
	margin-bottom: 10px;
  }
  
  .input100 {
	font-size: 15px;
	line-height: 1.5;
	color: $black;
	display: block;
	width: 100%;
	background: $white;
	height: 45px;
	padding: 0 30px 0 54px;
	border-radius: 5px;
	border: 1px solid #ecf0fa;
  }
  
  /*------------------------------------------------------------------
  [ Focus ]*/
  
  .focus-input100 {
	display: block;
	position: absolute;
	border-radius: 5px;
	bottom: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	box-shadow: 0px 0px 0px 0px;
	text-transform: uppercase;
	background: $primary-1;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  }
  
  .symbol-input100 {
	font-size: 17px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	position: absolute;
	border-radius: 5px;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding-left: 22px;
	pointer-events: none;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
	color: #828996;
  }
  
  .input100.focus-input100 + .symbol-input100 {
	text-transform: uppercase;
	background: $primary-1;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  }
  
  /*------------------------------------------------------------------
  [ Button ]*/
  
  .container-login100-form-btn {
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: 15px;
  }
  
  .login100-form-btn {
	line-height: 1.5;
	color: $white;
	width: 100%;
	height: 40px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 0 25px;
	border-radius: 5px;
  }
  
  /*------------------------------------------------------------------
  [ Responsive ]*/
  
  @media (max-width: 992px) {
	.wrap-login100 {
	  padding: 35px 90px 35px 85px;
	}
  
	.login100-pic {
	  width: 35%;
	  display: none;
	}
  
	.login100-form {
	  width: 100%;
	}
  }
  
  @media (max-width: 768px) {
	.wrap-login100 {
	  padding: 30px 80px 30px 80px;
	}
  
	.login100-form {
	  width: 100%;
	}
  }
  
  @media (max-width: 576px) {
	.wrap-login100 {
	  padding: 25px 15px 25px 15px;
	}
  }
  
  .btn-social {
	position: relative;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border: 1px solid #e2e7eb;
	padding-left: 60px;
	background-color: $white;
  
	> :first-child {
	  position: absolute;
	  left: 0;
	  top: 0;
	  bottom: 0;
	  width: 40px;
	  line-height: 36px;
	  font-size: 1.2em;
	  text-align: center;
	  border-right: 1px solid #ecf0fa;
	}
  
	&.btn-lg {
	  padding-left: 61px;
  
	  > :first-child {
		line-height: 45px;
		width: 45px;
		font-size: 1.8em;
	  }
	}
  
	&.btn-sm {
	  padding-left: 38px;
  
	  > :first-child {
		line-height: 28px;
		width: 28px;
		font-size: 1.4em;
	  }
	}
  
	&.btn-xs {
	  padding-left: 30px;
  
	  > :first-child {
		line-height: 20px;
		width: 20px;
		font-size: 1.2em;
	  }
	}
  }
  
  .btn-social-icon {
	position: relative;
	padding-left: 44px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 34px;
	width: 34px;
	padding: 0;
  
	> :first-child {
	  position: absolute;
	  left: 0;
	  top: 0;
	  bottom: 0;
	  width: 32px;
	  line-height: 34px;
	  font-size: 1.6em;
	  text-align: center;
	  border-right: 1px solid rgba(0, 0, 0, 0.2);
	}
  
	&.btn-lg {
	  padding-left: 61px;
  
	  > :first-child {
		line-height: 45px;
		width: 45px;
		font-size: 1.8em;
	  }
	}
  
	&.btn-sm {
	  padding-left: 38px;
  
	  > :first-child {
		line-height: 28px;
		width: 28px;
		font-size: 1.4em;
	  }
	}
  
	&.btn-xs {
	  padding-left: 30px;
  
	  > :first-child {
		line-height: 20px;
		width: 20px;
		font-size: 1.2em;
	  }
	}
  
	> :first-child {
	  border: none;
	  text-align: center;
	  width: 100%;
	  color: $white;
	}
  
	&.btn-lg {
	  height: 45px;
	  width: 45px;
	  padding-left: 0;
	  padding-right: 0;
	}
  
	&.btn-sm {
	  height: 30px;
	  width: 30px;
	  padding-left: 0;
	  padding-right: 0;
	}
  
	&.btn-xs {
	  height: 22px;
	  width: 22px;
	  padding-left: 0;
	  padding-right: 0;
	}
  }